<script>
/**
 * 用户登录系统路由组件，登录的用户可能是公司学员账号和公司管理平台账号
 */
export default {
  name: 'AccountRouterView',
}
</script>

<template>
  <div class="account-router-view">
    <div class="content">
      <div class="intro">
        <span class="intro-title">事智通</span>
      </div>
      <div class="form">
        <div class="form-content">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.account-router-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: $view-content-min-width;
  height: 100%;
  background-image: url('./assets/images/bg-main.png');
  background-repeat: no-repeat;
  background-position: -2px -1px;
  background-size: cover;

  .content {
    display: flex;
    width: 1000px;
    height: 650px;
    background-image: url('./assets/images/bg-fragment.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .intro {
      position: relative;
      flex: 1;

      .intro-title {
        position: absolute;
        top: 60px;
        left: 42px;
        color: #fff;
        font-size: 34px;
        letter-spacing: 3px;
      }
    }

    .form {
      position: relative;
      display: flex;
      flex: 1;
      justify-content: center;

      &-content {
        position: absolute;
        top: calc(50% - 250px);
        display: flex;
        width: 450px;
      }
    }
  }
}
</style>
