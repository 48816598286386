import { render, staticRenderFns } from "./NavigateSideBar.vue?vue&type=template&id=92563ace&scoped=true"
import script from "./NavigateSideBar.vue?vue&type=script&lang=js"
export * from "./NavigateSideBar.vue?vue&type=script&lang=js"
import style0 from "./NavigateSideBar.vue?vue&type=style&index=0&id=92563ace&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_css-loader@6.11.0_webpack@5.95.0__lodash@4.17.21__xd47jep3ckjrkwpvunpsddnmmi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92563ace",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Workspace\\qx-student-client\\node_modules\\.pnpm\\vue-hot-reload-api@2.3.4\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('92563ace')) {
      api.createRecord('92563ace', component.options)
    } else {
      api.reload('92563ace', component.options)
    }
    module.hot.accept("./NavigateSideBar.vue?vue&type=template&id=92563ace&scoped=true", function () {
      api.rerender('92563ace', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/StudentCenterRouterView/components/NavigateSideBar/NavigateSideBar.vue"
export default component.exports