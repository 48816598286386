import { A as $, a as N, c as v } from "./index-14ac1a4c.js";
import { C as h, a as g } from "./Enums-56ba9919.js";
const k = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0"
];
function l(i, n) {
  return Math.floor(Math.random() * (n - i) + i);
}
function K(i, n) {
  const t = n || k;
  let o = "";
  for (let e = 0; e < i; e++) {
    const r = l(0, t.length);
    o = o + t[r];
  }
  return o;
}
function p() {
  const i = Math.floor(Math.random() * 256).toString(16).length < 2 ? `0${Math.floor(Math.random() * 256).toString(16)}` : Math.floor(Math.random() * 256).toString(16), n = Math.floor(Math.random() * 256).toString(16).length < 2 ? `0${Math.floor(Math.random() * 256).toString(16)}` : Math.floor(Math.random() * 256).toString(16), t = Math.floor(Math.random() * 256).toString(16).length < 2 ? `0${Math.floor(Math.random() * 256).toString(16)}` : Math.floor(Math.random() * 256).toString(16);
  return `#${i}${n}${t}`;
}
const I = {
  width: 120,
  height: 40,
  padding: 10,
  lineNumber: 2,
  dotNumber: 80,
  bezierLineNumber: 2,
  imgType: "image/png",
  encoderOptions: 1,
  length: 4,
  dict: k
};
function O(i) {
  const { length: n, dict: t, ...o } = {
    ...I,
    ...i
  }, e = K(n, t), r = document.createElement("canvas"), s = o.height, a = o.width, m = o.padding, y = s - m, S = a - m;
  r.height = s, r.width = a;
  const c = r.getContext("2d");
  if (!c)
    return console.error("can not get canvas context"), Promise.resolve(null);
  const d = e.length;
  for (let u = 0; u < d; u++) {
    const f = e[u], b = l(y / 2, y);
    c.font = `${b}px Georgia`, c.fillStyle = p(), c.shadowBlur = l(-3, 3), c.shadowColor = p();
    const C = l(-30, 30), _ = m + u * S / d, M = s / 2 + b / 2;
    c.translate(_, M), c.rotate(C * Math.PI / 180), c.fillText(f, 0, 0, S / d), c.rotate(-C * Math.PI / 180), c.translate(-_, -M);
  }
  const T = o.lineNumber;
  for (let u = 0; u < T; u++)
    c.strokeStyle = p(), c.beginPath(), c.moveTo(l(0, a), l(0, s)), c.lineTo(l(0, a), l(0, s)), c.stroke();
  const w = o.dotNumber;
  for (let u = 0; u < w; u++)
    c.fillStyle = p(), c.beginPath(), c.arc(l(0, a), l(0, s), 1, 0, 2 * Math.PI), c.fill();
  const x = o.bezierLineNumber;
  for (let u = 0; u < x; u++)
    c.fillStyle = p(), c.beginPath(), c.moveTo(l(0, a), l(0, s)), c.bezierCurveTo(
      l(0, a),
      l(0, s),
      l(0, a),
      l(0, s),
      l(0, a),
      l(0, s)
    ), c.stroke();
  return new Promise((u) => {
    r.toBlob((f) => u({ blob: f, code: e }), o.imgType, o.encoderOptions);
  });
}
const G = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  create: O
}, Symbol.toStringTag, { value: "Module" }));
function U(i) {
  const n = new OSS({
    region: i.region,
    endpoint: i.endpoint,
    accessKeyId: i.AccessKeyId,
    accessKeySecret: i.AccessKeySecret,
    stsToken: i.SecurityToken,
    bucket: i.bucket
  });
  return {
    parserData(t) {
      if (Array.isArray(t))
        return t.map((o) => this.parserData(o));
      if (typeof t == "object" && t !== void 0 && t !== null) {
        const o = Object.keys(t);
        for (let e = 0; e < o.length; e++) {
          const r = o[e], s = t[r];
          t[r] = this.parserData(s);
        }
        return t;
      } else
        return typeof t == "string" ? this.parserUrl(t) : t;
    },
    parserUrl(t) {
      if (/<img\s+[^>]*?src=["'](.*?)["'][^>]*>/g.test(t)) {
        if (n)
          return t.replace(/<img\s+[^>]*?src=["'](.*?)["'][^>]*>/g, (o, e) => {
            const r = t.includes("?") ? /^https?:\/\/.*\.aliyuncs.com\/(.*)\?/ig.exec(e) : /^https?:\/\/.*\.aliyuncs.com\/(.*)/ig.exec(e);
            return o.replace(e, r != null && r.at(1) ? n.signatureUrl(r.at(1)) : e);
          });
      } else {
        const o = t.includes("?") ? /^https?:\/\/.*\.aliyuncs.com\/(.*)\?/ig.exec(t) : /^https?:\/\/.*\.aliyuncs.com\/(.*)/ig.exec(t), e = o == null ? void 0 : o.at(1);
        if (n) {
          if (e)
            return e.includes("sharedFile/icon/icon_user/") || e.includes("kpxt/avatar/") ? n.signatureUrl(e, { process: "image/resize,w_100/quality,q_80/format,jpg" }) : n.signatureUrl(e);
          if (t.startsWith("/kpxt/"))
            return n.signatureUrl(t);
        }
      }
      return t;
    },
    download(t, o) {
      const e = t.includes("?") ? /^https?:\/\/.*\.aliyuncs.com\/(.*)\?/ig.exec(t) : /^https?:\/\/.*\.aliyuncs.com\/(.*)/ig.exec(t), r = e == null ? void 0 : e.at(1);
      if (n && r) {
        const s = o ?? r.split("/").slice(-1).join(""), a = n.signatureUrl(r, {
          response: {
            "content-disposition": `attachment; filename=${encodeURIComponent(s)}`
          }
        }), m = document.createElement("a");
        return m.href = a, m.download = s, m.click(), !0;
      } else
        return !1;
    },
    downloadUrl(t, o) {
      const e = t.includes("?") ? /^https?:\/\/.*\.aliyuncs.com\/(.*)\?/ig.exec(t) : /^https?:\/\/.*\.aliyuncs.com\/(.*)/ig.exec(t), r = e == null ? void 0 : e.at(1);
      if (n && r) {
        const s = o ?? r.split("/").slice(-1).join("");
        return n.signatureUrl(r, {
          response: {
            "content-disposition": `attachment; filename=${encodeURIComponent(s)}`
          }
        });
      } else
        return !1;
    },
    async upload(t, o) {
      try {
        return await n.put(o, t), n.signatureUrl(o);
      } catch {
        return null;
      }
    },
    async exists(t) {
      try {
        return await n.head(t), !0;
      } catch (o) {
        return o.code === "NoSuchKey" ? !1 : null;
      }
    }
  };
}
function D(i) {
  let n = null;
  return i === h.线上课 ? n = g.线上课 : i === h.直播课 ? n = g.直播课 : i === h.培训任务 ? n = g.培训任务 : i === h.线下课 ? n = g.线下课 : i === h.图文课 && (n = g.图文课), n;
}
function P(i, n) {
  var a;
  const t = i.split(","), o = (a = t[0].match(/:(.\*?);/)) == null ? void 0 : a.at(1), e = atob(t[1]);
  let r = e.length;
  const s = new Uint8Array(r);
  for (; r--; )
    s[r] = e.charCodeAt(r);
  return new File([s], n, { type: o });
}
function j(i) {
  var s;
  const n = i.split(","), t = (s = n[0].match(/:(.\*?);/)) == null ? void 0 : s.at(1), o = atob(n[1]);
  let e = o.length;
  const r = new Uint8Array(e);
  for (; e--; )
    r[e] = o.charCodeAt(e);
  return new Blob([r], {
    type: t
  });
}
const A = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  base64ToBlob: j,
  base64ToFile: P
}, Symbol.toStringTag, { value: "Module" }));
class W {
  static GetJumpTimestampKey(n, t, o, e, r) {
    let s = `${t}_${o}_${n}_${e}`;
    return r && (s += `_${r}`), s;
  }
  /**
   * 设置视频继续播放信息
   * @param value 要存储的时间信息
   * @param uid 用户ID
   * @param vid 视频ID
   * @param t 机构ID
   * @param c 课程ID
   * @param p 培训ID
   */
  static SetJumpTimestamp(n, t, o, e, r, s) {
    const a = this.GetJumpTimestampKey(t, o, e, r, s);
    localStorage.setItem(a, n.toString());
  }
  /**
   * 获取设置的视频继续播放信息
   * @param uid 用户ID
   * @param vid 视频ID
   * @param t 机构ID
   * @param c 课程ID
   * @param p 培训ID
   */
  static GetJumpTimestamp(n, t, o, e, r) {
    const s = this.GetJumpTimestampKey(n, t, o, e, r), a = Number.parseFloat(localStorage.getItem(s) ?? "");
    return Number.isNaN(a) ? 0 : a;
  }
  /** 清空设置的视频继续播放信息 */
  static ClearJumpTimestamp(n, t, o, e, r) {
    const s = this.GetJumpTimestampKey(n, t, o, e, r);
    localStorage.removeItem(s);
  }
}
class z {
  static GetCourseWatchDurationKey(n, t, o, e, r) {
    let s = `course_watch_duration_${t}_${o}_${n}_${e}`;
    return r && (s += `_${r}`), s;
  }
  /**
   * 设置视频继续播放信息
   * @param value 要存储的时间信息
   * @param uid 用户ID
   * @param m 课件ID
   * @param t 机构ID
   * @param c 课程ID
   * @param p 培训ID
   */
  static SetCourseWatchDuration(n, t, o, e, r, s) {
    const a = this.GetCourseWatchDurationKey(t, o, e, r, s);
    localStorage.setItem(a, n.toString());
  }
  /**
   * 获取设置的视频继续播放信息
   * @param uid 用户ID
   * @param m 课件ID
   * @param t 机构ID
   * @param c 课程ID
   * @param p 培训ID
   */
  static GetCourseWatchDuration(n, t, o, e, r) {
    const s = this.GetCourseWatchDurationKey(n, t, o, e, r), a = Number.parseFloat(localStorage.getItem(s) ?? "");
    return Number.isNaN(a) ? 0 : a;
  }
  /** 清空设置的视频继续播放信息 */
  static ClearCourseWatchDuration(n, t, o, e, r) {
    const s = this.GetCourseWatchDurationKey(n, t, o, e, r);
    localStorage.removeItem(s);
  }
}
class J {
  static Key(n, t, o, e, r) {
    let s = `${t}_${o}_${n}_${e}`;
    return r && (s += `_${r}`), s += "_maxtime", s;
  }
  /**
   * 设置视频继续播放信息
   * @param value 要存储的时间信息
   * @param uid 用户ID
   * @param vid 视频ID
   * @param t 机构ID
   * @param c 课程ID
   * @param p 培训ID
   */
  static Set(n, t, o, e, r, s) {
    const a = this.Key(t, o, e, r, s), m = this.Get(t, o, e, r, s);
    n > m && localStorage.setItem(a, n.toString());
  }
  /**
   * 获取设置的视频继续播放信息
   * @param uid 用户ID
   * @param vid 视频ID
   * @param t 机构ID
   * @param c 课程ID
   * @param p 培训ID
   */
  static Get(n, t, o, e, r) {
    const s = this.Key(n, t, o, e, r), a = Number.parseFloat(localStorage.getItem(s) ?? "");
    return Number.isNaN(a) ? 0 : a;
  }
  /** 清空设置的视频继续播放信息 */
  static Clear(n, t, o, e, r) {
    const s = this.Key(n, t, o, e, r);
    localStorage.removeItem(s);
  }
}
const B = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  AliYunOSSHelper: $,
  AliYunOSSPlatformHelper: N,
  CCVideoMaxTimeTools: J,
  CCVideoTools: W,
  Constants: v,
  CourseWatchDurationTools: z,
  base64: A,
  getCourseCommentType: D,
  getOSSClient: U,
  verifyCode: G
}, Symbol.toStringTag, { value: "Module" }));
export {
  J as C,
  D as a,
  A as b,
  W as c,
  z as d,
  U as g,
  B as i,
  G as v
};
